<template>
  <el-menu router>
    <el-menu-item index="/admin" disabled>
      <el-icon><el-icon-house /></el-icon>
      Home
    </el-menu-item>
    <el-menu-item index="/admin/resources">
      <el-icon><el-icon-location /></el-icon>
      Resources
    </el-menu-item>
    <el-menu-item index="/admin/offerings">
      <el-icon><el-icon-goods /></el-icon>
      Offerings
    </el-menu-item>
    <el-menu-item index="/admin/bookings">
      <el-icon><el-icon-tickets /></el-icon>
      Bookings
    </el-menu-item>
    <el-sub-menu index="/admin/customers">
      <template #title>
        <el-icon><el-icon-connection /></el-icon>
        Customers
      </template>
      <el-menu-item index="/admin/members">
        Members
      </el-menu-item>
      <el-menu-item index="/admin/invitations">
        Invitations
      </el-menu-item>
      <el-menu-item index="/admin/groups">
        Groups
      </el-menu-item>
    </el-sub-menu>
    <el-menu-item disabled>
      <el-icon><el-icon-odometer /></el-icon>
      Analytics
    </el-menu-item>
    <el-menu-item disabled>
      <el-icon><el-icon-discount /></el-icon>
      Discounts
    </el-menu-item>
    <el-sub-menu index="/admin/site">
      <template #title>
        <el-icon><el-icon-connection /></el-icon>
        Organization Site
      </template>
      <el-menu-item index="/admin/site">
        View
      </el-menu-item>
      <el-menu-item index="/admin/site/edit">
        Edit
      </el-menu-item>
    </el-sub-menu>
    <el-menu-item index="/admin/settings/general">
      <el-icon><el-icon-setting /></el-icon>
      Settings
    </el-menu-item>
  </el-menu>
</template>
